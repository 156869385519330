/* Vendor CSS */

// Include all of Bootstrap
//@import 'bootstrap/scss/bootstrap';

// Include only parts of Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "custom";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Optionally include Bootstrap components imported in same order as bootstrap.scss
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/card";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/close";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/placeholders";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/spinners";
@import "bootstrap/scss/tables";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/transitions";

// 9. Important Bootstrap helpers (need to come after components)
@import "bootstrap/scss/helpers";

// Custom Fonts
@import "fonts/fonts";

// Font Awesome
@import "fontawesome/css/fontawesome.css";
@import "fontawesome/css/brands.css";
@import "fontawesome/css/regular.css";

// Flatpickr
@import "/node_modules/flatpickr/dist/flatpickr.css";

// Additional custom code here to override vendor classes
@import "override";

@import "/node_modules/nice-select2/dist/css/nice-select2.css";
