@import "custom";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

.btn {
    font-style: italic;
    font-weight: 600;
}

.btn-link {
    font-weight: normal;
}

// Override bootstrap's floating form padding logic to do it in a different order, since browsers are getting
// very confused. This shouldn't make a difference.
.form-floating {
    > .form-control,
    > .form-control-plaintext {
        &:focus,
        &:not(:placeholder-shown) {
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }
    }

    > .form-select {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
    }

    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
    }

    > .form-control-plaintext:-webkit-autofill {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
    }
}
