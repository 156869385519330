@font-face {
    font-family: "Okta Neue";
    font-style: normal;
    font-weight: 400;
    src:
        local("Okta Neue"),
        url("/vendor/fonts/oktaneue-regular.woff2") format("woff2"),
        url("/vendor/fonts/oktaneue-regular.otf") format("opentype");
}

@font-face {
    font-family: "Okta Neue";
    font-style: italic;
    font-weight: 400;
    src:
        local("Okta Neue"),
        url("/vendor/fonts/oktaneue-regular-italic.woff2") format("woff2"),
        url("/vendor/fonts/oktaneue-regular-italic.otf") format("opentype");
}

@font-face {
    font-family: "Okta Neue";
    font-style: normal;
    font-weight: 700;
    src:
        local("Okta Neue"),
        url("/vendor/fonts/oktaneue-bold.woff2") format("woff2"),
        url("/vendor/fonts/oktaneue-bold.otf") format("opentype");
}

@font-face {
    font-family: "Okta Neue";
    font-style: normal;
    font-weight: 600;
    src:
        local("Okta Neue"),
        url("/vendor/fonts/oktaneue-semibold.woff2") format("woff2"),
        url("/vendor/fonts/oktaneue-semibold.otf") format("opentype");
}

@font-face {
    font-family: "Okta Neue";
    font-style: italic;
    font-weight: 600;
    src:
        local("Okta Neue"),
        url("/vendor/fonts/oktaneue-semibold-italic.woff2") format("woff2"),
        url("/vendor/fonts/oktaneue-semibold-italic.otf") format("opentype");
}

// Font for the logo and anywhere text appears between the angle brackets
@font-face {
    font-family: "Boing";
    font-style: normal;
    font-weight: 700;
    src:
        local("Boing"),
        url("/vendor/fonts/boing-bold.woff2") format("woff2");
}
