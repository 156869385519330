.fab,
.fa-brands {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-brands: 'Font Awesome 6 Brands';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.ttf") format("truetype"); }

.fa-cc-amex::before { content: "\f1f3"; }
.fa-cc-diners-club::before { content: "\f24c"; }
.fa-cc-discover::before { content: "\f1f2"; }
.fa-cc-jcb::before { content: "\f24b"; }
.fa-cc-mastercard::before { content: "\f1f1"; }
.fa-cc-visa::before { content: "\f1f0"; }
.fa-facebook::before { content: "\f09a"; }
.fa-instagram::before { content: "\f16d"; }
.fa-linkedin::before { content: "\f08c"; }
.fa-stripe::before { content: "\f429"; }
.fa-twitter::before { content: "\f099"; }
